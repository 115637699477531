
import { defineComponent, computed, ref, watch } from "vue";
import InputText from "@/components/forms/InputText.vue";
import Select from "@/components/forms/Select.vue";
import { InputTextMaskTypes } from "@/core/enums/input-text-mask-types";
import { getModule } from "vuex-module-decorators";
import NewClientModule from "@/store/modules/client/modules/new-client";
import { getOptions } from "@/core/enums/customer-type";
import * as Validations from "yup";
import { RfcFormat, Phone10Digits } from "@/core/shared/ValidationRegex";
import { possibleValues } from "@/core/enums/customer-type";
import GeneralClientInfo from "@/models/clients/GeneralClientInfo";
import {  useForm } from "vee-validate"

export default defineComponent({
  components: {
    InputText,
    Select,
  },
  setup() {
    const module = getModule(NewClientModule);
    const validationSchema = Validations.object().shape({
      rfc: Validations.string().matches(RfcFormat, { excludeEmptyString: true, message: 'Incorrect Format RFC' }).required()
      , folio: Validations.string().default('')
      , name: Validations.string().default('').required().max(50)
      , curp: Validations.string().default('').optional()//.min(18).max(18)
      , email: Validations.string().default('').email().required()//.when(['rfc'], (rfc: string, schema) => {
      //   return rfc && (rfc.length > 0) ? schema.required() : schema.optional()
      // })
      , phone: Validations.string().matches(Phone10Digits, { excludeEmptyString: true, message: 'Formato numerico de telefono incorrecto' }).default('').required()
      , wayToPayId: Validations.string().nullable().required()
      , paymentMethodId: Validations.string().required()
      , typeUseCFDIId: Validations.string().required()//USO DEL CFDI
      , customerType: Validations.mixed().required().oneOf([...possibleValues], "Seleccione una opción")
      , regimeId: Validations.string().required()
    });
    const { resetForm, handleSubmit, meta, setValues } = useForm<GeneralClientInfo >({
      validationSchema: validationSchema,
    });

    const handleStep = handleSubmit((values) => {//NewClient
      module.SaveFormGeneralClientInfo(values);
    });

    const IsCorrectForm = async () => {
      await handleStep();
      if (!meta.value.valid) {
        throw new Error("Not Valid Form GeneralInfo");
      }
    }
    const reset = () => resetForm();
    //from store
    const wayToPayOptions = computed(() => module.selects.wayToPayOptions);
    const paymentMethods = computed(() => module.selects.paymentMethodsOptions);
    const useOptions = computed(() => module.selects.cfdiOptions);
    const isUpdateForm = computed(() => module.IsUpdateForm /*call store*/);
    const customerTypes = ref(getOptions());
    const regimeOptions = computed(() => module.selects.regimeOptions);

    watch(() => module.getValuesForm, (newValueForm) => {
      //se asigna los valores del formulario
      let dataForm = {
          curp: newValueForm.curp
          , customerType: newValueForm.customerType
          , folio: newValueForm.code
          , email: newValueForm.email
          , name: newValueForm.name
          , phone: newValueForm.phone
          , rfc: newValueForm.rfc
          , regimeId: newValueForm.regimeId
          , typeUseCFDIId: newValueForm.typeUseCFDIId
          , wayToPayId: newValueForm.wayToPayId
          , paymentMethodId: newValueForm.paymentMethodId
          , 
      } as GeneralClientInfo
      setValues(dataForm);
    });

    return {
      wayToPayOptions,
      paymentMethods,
      useOptions,
      InputTextMaskTypes,
      isUpdateForm,
      customerTypes,
      regimeOptions,
      handleStep
      , IsCorrectForm
      , reset
    };
  },
});
