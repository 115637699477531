
import { defineComponent, computed, ref, watch } from "vue";
import InputText from "@/components/forms/InputText.vue" 
import SelectFilterRemote from "@/components/forms/SelectFilterRemote.vue" 
import NewClientModule from "@/store/modules/client/modules/new-client";
import { getModule } from "vuex-module-decorators";
import l from "lodash";
import * as Validations from "yup";
import HomeAddress from "@/models/clients/HomeAddress";
import {  useForm } from "vee-validate"

export default defineComponent({
    components:{
        InputText
        , SelectFilterRemote
    },
    emits: ['cleanStateSelected', 'cleanPopulationSelected']
    ,setup(_, { emit }){
        const module = getModule(NewClientModule);
        const disabledState = ref(true);
        const countryId = ref('');//pais seleccionado
        const countryIdMex = ref('961fe355-7067-4b8d-bc26-9892a74b3a63');//MEXICO POR DEFECTO
        const stateIdCampeche = ref('d33fda11-2cd9-4ae6-be9a-30df4aa6c309');//estado por defecto, CAMPECHE

        const disabledPopulation = ref(true);
        const stateId = ref('');//pais seleccionado

        const validationSchema = Validations.object().shape({
            street: Validations.string().max(50).required()
            , number: Validations.string().default('Sin número').max(10).required()
            , interiorNumber: Validations.string().optional().max(3)
            , crosses: Validations.string().max(50).required()
            , block: Validations.string().max(50).required()
            , zipCode: Validations.string().max(5).required()
            , countryId: Validations.string().required()
            , stateId: Validations.string().required()
            , populationId: Validations.string().required()
            , fax: Validations.string().optional().max(10)
        });
        const { resetForm, handleSubmit, meta, setValues, setFieldValue } = useForm<HomeAddress>({
            validationSchema: validationSchema,
        });
        const handleStep = handleSubmit((values) => {//NewClient
            module.SaveFormHomeAddress(values);
        });
        const IsCorrectForm = async () => {
            /**asignar por defecto el valor de mexico y campeche por mientras */
            setFieldValue("countryId", countryIdMex.value);
            setFieldValue("stateId", stateIdCampeche.value);

            await handleStep();
            if (!meta.value.valid) {
                throw new Error("Not Valid Form HomeAddress");
            }
        }
        const reset = () => resetForm();

        //from store
        const populationOptions = computed(() => module.selects.populationOptions);
        const stateOptions = computed(() => module.selects.stateOptions);
        const countryOptions = computed(() => module.selects.countryOptions);

        const countryChange =  l.throttle((idSelected: string) => { //(value: string) => {
            //si cambia el valor indicar que ya se puede activar el otro control
            disabledState.value = false;
            countryId.value = idSelected;
         },650, {leading: false});
        const searchCountry = l.debounce((query: string) => {
            module.getComboCountries(query)
        }, 550, {leading: false, trailing: true})
        const clearComboState = () => {
            disabledState.value = true;
            countryId.value = '';
            emit('cleanStateSelected')
        }

        const searchState = l.debounce((query: string) => {
            module.getComboStates({ filterName: query, countryId: countryId.value });
        }, 550, {leading: false, trailing: true})//(filtername: string) => 
        const stateChange =  l.throttle((idSelected: string) => {//(value: string) => {
            //si cambia el valor indicar que ya se puede activar el otro control
            disabledPopulation.value = false;
            stateId.value = idSelected;
        },650, {leading: false});

        const clearComboPopulation = () => {
            disabledPopulation.value = true;
            stateId.value = '';
            emit('cleanPopulationSelected')
        }
        const searchPopulation = l.debounce((query: string) => {
            module.getComboPopulations({ filterName: query, countryId: countryIdMex.value, stateId: stateIdCampeche.value });
        }, 550, {leading: false, trailing: true})//(filtername: string) => ;

         watch(() => module.getValuesForm, (newValueForm) => {
             //se asigna los valores del formulario
             let valueHomeAddress = newValueForm.homeAddress;
             if (valueHomeAddress) {
                let dataForm = {
                    street: valueHomeAddress.street
                    , crosses: valueHomeAddress.crosses
                    , block: valueHomeAddress.block
                    , number: valueHomeAddress.number
                    , interiorNumber: valueHomeAddress.interiorNumber
                    , populationId: valueHomeAddress.population
                    , countryId: valueHomeAddress.countryId
                    , stateId: valueHomeAddress.stateId
                    , zipCode: valueHomeAddress.zipCode
                    , fax: valueHomeAddress.fax
                } as HomeAddress
                setValues(dataForm);
            }
         });

        return {
            populationOptions
            , countryOptions
            , stateOptions
            , disabledPopulation
            , disabledState
            , countryChange
            , searchCountry
            , clearComboState
            , stateChange
            , searchState
            , clearComboPopulation
            , searchPopulation
            , handleStep
            , IsCorrectForm
            , reset
        }
    }
})
